<template>
    <div style="background:#fff;">
        <a-layout-content style="padding:20px">
            <a-layout style="background:#fff;">
                <a-layout-header style="height:initial;background:#fff;padding: 0;">
                    <a-form layout="horizontal">
                        <a-form-item style="margin-bottom:10px;">
                            <a-form layout="inline">
                                <a-row>

                                    <a-col :span="20">
                                        <a-form-item>
                                            <label class="yj-partner-check_lab">红包类型：</label>
                                            <a-radio-group v-model="PromotionType" @change="getTableData">
                                                <a-radio-button value="-1">全部</a-radio-button>
                                                <a-radio-button value="0">拉新</a-radio-button>
                                                <a-radio-button value="1">购买</a-radio-button>
                                                <a-radio-button value="2">领券</a-radio-button>
                                                <a-radio-button value="3">阅读</a-radio-button>
                                            </a-radio-group>
                                        </a-form-item>

                                    </a-col>
                                </a-row>

                            </a-form>
                        </a-form-item>
                    </a-form>
                </a-layout-header>
                <a-layout-content>
                    <div class="yj_list">
                        <a-table :columns="columns" bordered
                                 rowKey="ID"
                                 :data-source="tableData"
                                 :pagination="pagination"
                                 @change="pagerChange"
                                 class="yj-table-img" style=" min-height:calc(100vh - 314px)">
                            <span slot="action" slot-scope="text, record">
                                <a @click="showtaskDetail(record.ID)">详情</a>
                                <a-divider type="vertical" />
                                <a @click="showtaskDetailedInfo(record.ID)">明细</a>

                            </span>
                        </a-table>
                    </div>
                </a-layout-content>
            </a-layout>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "红包类型",
                        customRender: (text, item) => {
                            var rt;
                            switch (item.OrRewardsType) {
                                case 0:
                                    rt = "拉新红包";
                                    break;
                                case 1:
                                    rt = "购买红包";
                                    break;
                                case 2:
                                    rt = "领券红包";
                                    break;
                                case 3:
                                    rt = "阅读红包";
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "红包名称",
                        dataIndex: "Name",
                        width: 300,
                        ellipsis: true
                    },
                    {
                        title: "目标用户类型",
                        dataIndex: "TargetUserType",
                        customRender: (text, item) => {
                            var rt;
                            switch (item.TargetUserType) {
                                case 0:
                                    rt = "分享人";
                                    break;
                                case 1:
                                    rt = "阅读人";
                                    break;
                                case 2:
                                    rt = "团员";
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: "红包金额",
                        dataIndex: "OrRewardsMoney",
                        width: 200,
                        ellipsis: true
                    },
                     {
                        title: "剩余金额",
                        dataIndex: "AccountMoney",
                        width: 200,
                        ellipsis: true
                    },
                      {
                        title: "创建人",
                        dataIndex: "UserName",
                        width: 120,
                        ellipsis: true
                    },
                    {
                        title: "创建时间",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.OrInputTime, 'yyyy年MM月dd日')
                        }
                    },
                   
                    {
                        title: "状态",
                        customRender: (text, item) => {
                            var rt;
                            switch (item.ReleaseStatus) {
                                case 0:
                                    rt = "未生效";
                                    break;
                                case 1:
                                    rt = "已生效";
                                    break;
                                case 2:
                                    rt = "已结束";
                                    break;
                            }
                            return rt;
                        }
                    },
                    {
                        title: '操作',
                        scopedSlots: { customRender: 'action' },
                    }
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                PromotionType: "-1"
            };
        },
        props: {//组件属性
        },
        methods: {//方法

            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/PromoteStimulateRedEnvelopesConfigList",
                    data: {
                        pageIndex: self.pagination.current,
                        PromotionType: self.PromotionType,
                    },
                    OnSuccess: function (data) {
                           data.data.Data.forEach(element => {
                               element.OrRewardsMoney = parseFloat(element.OrRewardsMoney).toFixed(2);
                                 element.AccountMoney = parseFloat(element.AccountMoney).toFixed(2);
                        });
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            //详情
            showtaskDetail: function (e) {
                var self = this;
                self.$router.push({
                    name: "PromoteStimulateRedEnvelopesConfig_Detail",
                    query: { ID: e }
                });
            },
            //明细
            showtaskDetailedInfo: function (e) {
                var self = this;
                self.$router.push({
                    name: "PromoteStimulateRedEnvelopesConfig_DetailInfo",
                    query: { ID: e }
                });
            }




        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },
       
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };


</script>